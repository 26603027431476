<script setup>
import {
  defineProps, toRefs,
  ref,
  watchEffect,
} from 'vue';
import AppSpinner from '@/components/AppSpinner.vue';
import EventService
  from '@/api/EventService.api';

const props = defineProps({
  eventId: { type: Number, default: null },
});
const { eventId } = toRefs(props);

const enabled = ref(false);

const {
  isLoading, isError, data, error, refetch, isFetching,
} = EventService.Query.findOneMap(eventId, { enabled });

watchEffect(() => {
  enabled.value = typeof eventId.value === 'number';
});
</script>

<template>
  <div class="map__container">
    <span
      v-if="isLoading"
      class="map__content skeleton loading-shimmer d-flex align-items-center justify-content-center rounded-3"
    />
    <div
      v-else-if="error"
      class="map__content d-flex align-items-center justify-content-center rounded-3"
    >
      <div class="h3 fw-bold">
        No Map Available
      </div>
    </div>
    <img
      v-else
      width="600"
      height="300"
      :src="data"
      class="map__content rounded-3"
    >
  </div>
</template>

<style lang="scss" scoped>
.map__container{
aspect-ratio: 2/1;
  width: 100%;
  max-width: 600px;
  .map__content{
    width: 100%;
    height: 100%;
  }
}
</style>
