<script setup>
import { computed, defineProps, toRefs } from 'vue';

const props = defineProps({
  active: { type: Boolean, default: false },
  multiple: { type: Boolean, default: false },
});

const { active, multiple } = toRefs(props);

const fieldType = computed(() => (multiple.value ? 'checkbox' : 'radio'));
</script>

<template>
  <button
    type="button"
    class="dropdown-item d-flex align-items-center"
  >
    <span class="form-check pointer-events-none">
      <input
        class="form-check-input"
        :type="fieldType"
        :checked="active"
      >
    </span>
    <slot />
  </button>
</template>
