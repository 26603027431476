<script setup>
import {
  toRefs, defineProps, computed,
} from 'vue';

// Props
const props = defineProps({
  title: { type: String, default: 'No Results' },
  icon: { type: String, default: 'search' },
});
const { title, icon } = toRefs(props);
</script>

<template>
  <div class="text-faint text-center mt-5 p-5">
    <AppIcon
      v-if="icon"
      :name="icon"
      class="mb-2"
      size="48"
    />
    <div
      class="h2 fw-bold"
    >
      {{ title }}
    </div>
    <slot />
  </div>
</template>

<style lang="scss" scoped>

</style>
