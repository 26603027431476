<script setup>
import {
  defineProps, defineEmits, watchEffect, ref, toRefs, watch,
} from 'vue';
import { DatePicker } from 'v-calendar';
import { useVModel } from '@vueuse/core';
import { Dropdown } from 'bootstrap';

const props = defineProps({
  modelValue: { type: Object, default: () => ({ start: null, end: null }) },
  searchActive: { type: Boolean, default: false },
});
const { searchActive } = toRefs(props);
const emit = defineEmits(['update:modelValue']);

const dateRange = useVModel(props, 'modelValue', emit);
const calendarToggle = ref(null);
const dropDown = ref(null);

watchEffect(() => {
  if (calendarToggle.value !== null) {
    dropDown.value = new Dropdown(calendarToggle.value);
  }
});

watch(searchActive, () => {
  dropDown.value.hide();
});

watchEffect(() => {
  if (dateRange.value !== null) {
    dropDown.value.hide();
  }
});
</script>
<template>
  <div class="dropdown flex-grow-0 flex-shrink-0 min-w-0">
    <button
      ref="calendarToggle"
      class="btn btn-icon text-dark flex-grow-0 px-2 border-0 dropdown-toggle h-100 no-caret"
      :class="[{'btn-icon bg-transparent text-dark':searchActive}, {'btn-icon bg-transparent text-white':!searchActive}]"
      type="button"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
    >
      <AppIcon name="date_range" />
    </button>
    <div
      class="dropdown-menu"
      aria-labelledby="dropdownMenuButton1"
    >
      <DatePicker
        v-model="dateRange"
        :max-date="new Date()"
        is-range
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.vc-container) {
  border: none;
  font-family: inherit;
  &.vc-blue{
    --blue-100: red;
    --blue-200: var(--bs-light);
    --blue-300: red;
    --blue-400: red;
    --blue-500: red;
    --blue-600: var(--bs-primary);
    --blue-700: var(--bs-primary);
    --blue-800: var(--bs-primary);
    --blue-900: var(--bs-primary);
  }
}

.btn.dropdown-toggle{
  transition: unset;
}
</style>
