<script setup>
import {
  ref, watch, computed, reactive, toRefs, defineProps, defineEmits,
  watchEffect,
} from 'vue';
import EventService from '@/api/EventService.api';

import EventListRow from '@/components/events/EventListRow.vue';
import EventListLoadingState from '@/components/events/EventListLoadingState.vue';

// Props
const props = defineProps({
  open: { type: Boolean, default: false },
  emailId: { type: String, default: null },
});
const { open, emailId } = toRefs(props);

const enabled = computed(() => emailId.value !== null);

const {
  isLoading, isError, data, error, refetch, isFetching,
} = EventService.Query.findAllByEmail(emailId, { enabled });

const eventListData = computed(() => data?.value ?? []);
const hasEntries = computed(() => eventListData.value.length > 0);

</script>
<template>
  <div
    v-if="open"
    class="h-100 border-start flex-grow-0 info-panel sticky-top min-w-0"
  >
    <div class="p-3 bg-white d-flex align-items-center border-bottom sticky-top">
      <h4 class="mb-0 d-flex align-items-center">
        Events
      </h4>
      <span class="ms-3 badge bg-secondary rounded-pill text-dark py-1 px-2 small">{{ eventListData.length }}</span>
      <div class="ms-auto justify-content-end">
        <div
          class="btn-group ms-3"
          role="group"
          aria-label="Basic example"
        >
          <!--        <button type="button" class="btn btn-link btn-icon mr-3"><span class="material-icons-outlined">filter_list</span></button>-->
          <button
            type="button"
            class="btn btn-link btn-icon py-0 pe-0"
            :class="[{'disabled':isLoading}]"
            @click="refetch()"
          >
            <AppSpinner v-if="isFetching" />
            <AppIcon
              v-else
              name="refresh"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="list-group list-group-flush">
      <template v-if="isLoading">
        <EventListLoadingState
          v-for="n in 7"
          :key="n"
          :opacity="1-((n-1)/7)"
        />
      </template>
      <template v-else-if="hasEntries">
        <EventListRow
          v-for="event in eventListData"
          :key="event.id"
          :event="event"
        />
      </template>
      <template v-else>
        <div class="text-faint text-center mt-5 pt-5">
          <div class="h1 fw-bold">
            No Events
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
