<script setup>
import {
  toRefs, defineProps, computed,
} from 'vue';

import { format } from 'date-fns';

import useFilterSearchStore
  from '@/store/filterSearch.store';

import PersonIdentifier from '@/components/indicators/PersonIdentifier.vue';
import {
  storeToRefs,
} from 'pinia';
import AppIcon
  from '@/components/AppIcon.vue';

const filterSearchStore = useFilterSearchStore();
const { history } = storeToRefs(filterSearchStore);
const { addSubject, addPerson, clearHistory } = filterSearchStore;

const hasPeople = computed(() => history.value.people.length > 0);
const hasSubjects = computed(() => history.value.subjects.length > 0);

</script>

<template>
  <div>
    <div class="d-flex align-items-center p-3">
      <h6 class="m-0">
        Recent Searches
      </h6>
      <button
        class="btn btn-light btn-sm ms-auto"
        @click="clearHistory"
      >
        Clear
      </button>
    </div>

    <div
      v-if="hasPeople"
      class="row border-bottom g-0 px-3 pb-3"
    >
      <div
        v-for="person in history.people"
        :key="person"
        class="col"
      >
        <button
          class="person-block d-flex flex-column align-items-center w-100 btn btn-light border-0 bg-transparent"
          @click="addPerson(person)"
        >
          <PersonIdentifier
            class="flex-shrink-0 fs-5 p-2"
            :name="person"
          />
          <span class="fw-bold small ellipsis w-100 mt-2">
            {{ person }}
          </span>
        </button>
      </div>
    </div>
    <div
      v-if="hasSubjects"
      class="list-group list-group-flush border-0"
    >
      <button
        v-for="subject in history.subjects"
        :key="subject.subject"
        type="button"
        class="list-group-item list-group-item-action d-flex align-items-center small"
        @click="addSubject(subject);"
      >
        <AppIcon
          class="me-3 flex-shrink-0"
          name="history"
        /> <span class="fw-bold me-3">{{ subject }}</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.col{
  min-width: 20%;
  max-width: 20%;
}
</style>
