<template>
  <img
    v-if="iconUrl"
    :src="iconUrl"
    width="18"
  >
</template>

<script setup>
import {
  defineProps, toRefs,
} from 'vue';

const props = defineProps({
  iconUrl: { type: String, default: null },
});

const { iconUrl } = toRefs(props);

</script>

<style lang="scss" scoped>

</style>
