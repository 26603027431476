<template>
  <span
    v-if="isLoading"
    class="skeleton loading-shimmer"
    :class="classList"
    v-bind="$attrs"
  />
  <template v-else>
    <slot />
  </template>
</template>

<script setup>
import {
  defineProps, toRefs, inject, computed,
} from 'vue';

const props = defineProps({
  class: { type: String, default: 'skeleton--100' },
  isLoading: { type: Boolean, default: null },
});
const { class: classList, isLoading: loadingProp } = toRefs(props);

const loadingInject = inject('isLoading', false);

const isLoading = computed(() => (loadingProp.value !== null ? loadingProp.value : loadingInject.value));
</script>

<style lang="scss" scoped>

</style>
