<script setup>
import {
  defineProps, toRefs, computed, ref, watchEffect,
} from 'vue';

const props = defineProps({
  name: { type: String, default: '' },
  isLoading: { type: Boolean, default: false },
});
const { name, isLoading } = toRefs(props);

const initials = computed(() => {
  const initialsArray = name.value.length > 0 ? name.value.split(new RegExp('[^a-zA-Z0-9](?=.*@)', 'g')).map((n) => n[0]) : [];
  let extractInitials = '';

  if (initialsArray.length > 1 && typeof initialsArray[1] !== 'undefined') {
    extractInitials = [...initialsArray[0], ...initialsArray[1]].join('');
  } else if (initialsArray.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    extractInitials = initialsArray[0];
  }
  return extractInitials.toUpperCase();
});

// const colors = [['#FFA6A6', '#812826'], ['#FFDBA6', '#815C27'], ['#EDFFA6', '#6E8127'], ['#B8FFA6', '#398127'], ['#A6FFCA', '#27814B'], ['#A6FFFF', '#278181'], ['#A6CAFF', '#274B81'], ['#B8A6FF', '#392781'], ['#EDA6FF', '#6E2781']];
const colors = [['#FFDBA6', '#815C27'], ['#A6FFCA', '#27814B'], ['#A6FFFF', '#278181'], ['#A6CAFF', '#274B81'], ['#B8A6FF', '#392781'], ['#EDA6FF', '#6E2781']];

const userColour = computed(() => {
  const nameLength = name.value.length;
  let hash = 0;
  if (nameLength === 0) {
    return 'background-color:#ecedf3;color:#acadb7;';
  }
  for (let i = 0; i < nameLength; i += 1) {
    hash = name.value.charCodeAt(i) + ((hash << 5) - hash);// eslint-disable-line no-bitwise
    hash &= hash; // eslint-disable-line no-bitwise
  }
  hash = ((hash % colors.length) + colors.length) % colors.length;
  return `background-color:${colors[hash][0]};color:${colors[hash][1]}`;
});

const avatarStyle = computed(() => (isLoading.value ? '' : userColour.value));

</script>

<template>
  <span
    class="account_identifier d-flex align-items-center justify-content-center rounded-circle"
    :class="[{'skeleton loading-shimmer':isLoading}]"
    :style="avatarStyle"
  >
    <span class="user__initials fw-bold">
      {{ isLoading ? '' : initials }}
    </span>
  </span>
</template>

<style lang="scss" scoped>
.account_identifier{
aspect-ratio: 1/1;
  width: 3.5em;
  height: unset;

  .user__initials{
    text-decoration: none;
  }
}
</style>
