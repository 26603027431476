<script setup>
import {
  defineProps, toRefs, ref, watchEffect, defineEmits,
} from 'vue';

const props = defineProps({
  isLoading: { type: Boolean, default: false },
  classic: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
});
const {
  isLoading: loading, classic, disabled,
} = toRefs(props);

const emit = defineEmits(['click']);

const button = ref(null);
const buttonWidthStyle = ref('');
const isLoading = ref(false);
const isDisabled = ref(false);

const startLoading = () => {
  buttonWidthStyle.value = `width:${button.value.offsetWidth + 1}px;height:${button.value.offsetHeight}px;`;
  isLoading.value = true;
};

watchEffect(() => {
  if (loading.value && button.value !== null) {
    startLoading();
  } else {
    isLoading.value = false;
  }
});

watchEffect(() => {
  isDisabled.value = disabled.value;
});

const clickButton = () => {
  if (!isDisabled.value) {
    if (classic.value) {
      startLoading();
      isDisabled.value = true;
    }
  }
  emit('click');
};

</script>

<template>
  <button
    ref="button"
    class="btn"
    :style="buttonWidthStyle"
    :disabled="disabled"
    @click="clickButton"
  >
    <transition
      name="slide-fade"
      mode="out-in"
    >
      <span
        v-if="!isLoading"
        class="d-inline-block"
      >
        <slot />
      </span>
      <span
        v-else
        class="d-inline-block"
      >
        <span
          class="spinner-border spinner-border-sm"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </span>
      </span>
    </transition>
  </button>
</template>

<style lang="scss" scoped>

</style>
