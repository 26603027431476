<script setup>
import { defineProps, ref, toRefs } from 'vue';
import FilterButton from '@/components/FilterButton.vue';

import useFilterSearchStore from '@/store/filterSearch.store';
import { storeToRefs } from 'pinia';

const filterSearchStore = useFilterSearchStore();
const {
  direction, sort, hasFilter, hasAnyFilter,
} = storeToRefs(filterSearchStore);
const {
  toggleFilter,
} = filterSearchStore;
</script>

<template>
  <div class="dropdown">
    <button
      type="button"
      class="btn btn-link btn-icon p-0 dropdown-toggle no-caret"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
    >
      <AppIcon name="filter_list" />
    </button>
    <ul
      class="dropdown-menu py-1"
    >
      <li>
        <h6 class="dropdown-header py-2">
          Status
        </h6>
      </li>

      <li>
        <FilterButton
          :active="hasFilter('status', 'accepted')"
          multiple
          @click="toggleFilter('status', 'accepted')"
        >
          Accepted
        </FilterButton>
      </li>
      <li>
        <FilterButton
          :active="hasFilter('status', 'delivered')"
          multiple
          @click="toggleFilter('status', 'delivered')"
        >
          Delivered
        </FilterButton>
      </li>
      <li>
        <FilterButton
          :active="hasFilter('status', 'failed')"
          multiple
          @click="toggleFilter('status', 'failed')"
        >
          Failed
        </FilterButton>
      </li>
      <FilterButton
        :active="hasFilter('status', 'opened')"
        multiple
        @click="toggleFilter('status', 'opened')"
      >
        Opened
      </FilterButton>
      <FilterButton
        :active="hasFilter('status', 'clicked')"
        multiple
        @click="toggleFilter('status', 'clicked')"
      >
        Clicked
      </FilterButton>
      <li><hr class="dropdown-divider"></li>

      <li>
        <h6 class="dropdown-header py-2">
          Sort By
        </h6>
      </li>
      <li>
        <FilterButton
          :active="sort === 'timestamp'"
          @click="sort = 'timestamp'"
        >
          Date
        </FilterButton>
      </li>
      <li>
        <FilterButton
          :active="sort === 'subject'"
          @click="sort = 'subject'"
        >
          Subject
        </FilterButton>
      </li>
      <li>
        <FilterButton
          :active="sort === 'recipient'"
          @click="sort = 'recipient'"
        >
          Recipient
        </FilterButton>
      </li>
      <li>
        <FilterButton
          :active="sort === 'size'"
          @click="sort = 'size'"
        >
          Size
        </FilterButton>
      </li>
      <li><hr class="dropdown-divider"></li>
      <li>
        <FilterButton
          :active="direction === 'asc'"
          @click="direction = 'asc'"
        >
          Sort Ascending
        </FilterButton>
      </li>
      <li>
        <FilterButton
          :active="direction === 'desc'"
          @click="direction = 'desc'"
        >
          Sort Descending
        </FilterButton>
      </li>
    </ul>
  </div>
</template>
