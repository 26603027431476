<script setup>
import {
  toRefs, defineProps, computed,
} from 'vue';

import { format } from 'date-fns';

import useFilterSearchStore
  from '@/store/filterSearch.store';

import PersonIdentifier from '@/components/indicators/PersonIdentifier.vue';
import {
  storeToRefs,
} from 'pinia';
import AppIcon
  from '@/components/AppIcon.vue';

const filterSearchStore = useFilterSearchStore();
const { committedQuery } = storeToRefs(filterSearchStore);
const { removePerson, removeSubject, removeDateRange } = filterSearchStore;

const hasDateRange = computed(() => committedQuery.value.dateRange !== null);
const formattedDateRange = computed(() => {
  if (hasDateRange.value) {
    return `${format(committedQuery.value.dateRange.start, 'dd/MM/yy')} — ${format(committedQuery.value.dateRange.end, 'dd/MM/yy')}`;
  }
  return '';
});
</script>

<template>
  <div class="d-flex align-items-center flex-shrink-1 min-w-0">
    <button
      v-if="hasDateRange"
      key="dateRange"
      class="subject-btn btn btn-primary rounded-pill no-wrap  d-flex align-items-center px-1 py-0 me-1 small btn-sm"
      @click="removeDateRange"
    >
      {{ formattedDateRange }}
    </button>
    <button
      v-for="person in committedQuery.people"
      :key="person"
      class="btn rounded-circle p-0 me-1"
      @click="removePerson(person)"
    >
      <PersonIdentifier
        class="flex-shrink-0 small"
        style="width:24px"
        :name="person"
      />
    </button>
    <button
      v-for="subject in committedQuery.subjects"
      :key="subject"
      class="subject-btn btn btn-light rounded-pill ellipsis px-1 py-0 me-1 small btn-sm"
      @click="removeSubject(subject)"
    >
      {{ subject }}
    </button>
  </div>
</template>

<style lang="scss" scoped>
.subject-btn{
font-size: 80%;
}
</style>
